
.dashboardPage {
    text-align: left;
    font-family: "Inconsolata", monospace;
}
.dashNavi {
    position: fixed;
    border-bottom: 1px solid white;
    width: 100%;
    padding-bottom: 10px;
    background-color: #121212;
    border-left: none;
    font-size: 22px;
    gap: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.dashNaviLinks {
    display: flex;
    justify-content: center;
    gap: 30px;
}

.groupsList {
    display: flex;
    gap: 10px;
    flex-direction: column;
    font-size: 20px;
}
.groupName {
    font-weight: 800;
    padding: 5px;
}

.groups{
    display: flex;
    flex-direction: column;
    border: 1px solid white;
}
.membersList li {
    width: 100%;
    border: 1px solid white;
    padding: 5px;
}
.membersList {
    display: flex;
    list-style-type: none;
}

.content {
    padding-top: 155px;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}
.dashNavi p {
    font-size: 30px;
    font-weight: 600;
}
.dashNavi a {
    text-decoration: none;
    color: rgb(146, 146, 146);
    transition: .3s color;
}
.dashNavi a:is(:focus, :hover){
    color: green;
}

.dashNavi a#logout:is(:focus, :hover){
    color: red;
}
