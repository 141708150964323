.skladkiList {
    border: 1px solid;
    overflow-x: scroll !important;
}
.skladkiList th, .skladkiList td {
    padding: 10px;
}
.skladkiList th {
    font-size: 24px;
    border-bottom: 1px solid white;
}
.skladkiList tr:not(:last-child) {
    border-bottom: 1px solid white;
}
.skladkiList td:not(:last-child), .skladkiList th:not(:last-child) {
    border-right: 2px solid white;
}

.skladka-nadplata {
    background-color: gold;
    color: black;
}
.skladka-niezaplacone {
    background-color: red;
}
.skladka-zaplacone {
    background-color: green;
}

@media (min-width: 896px) {
    .skladkiList {
        width: 100%;
    }
}