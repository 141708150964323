.dashboardPage h1{
    text-align: center;
    font-size: 36px;
    line-height: 100px;
}
.dashboardPage {
    text-align: left;
    font-family: "Inconsolata", monospace;
}
.dashNavi {
    position: fixed;
    border-bottom: 1px solid white;
    width: 100%;
    padding-bottom: 10px;
    background-color: #121212;
    border-left: none;
    font-size: 22px;
    gap: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
}

@media (min-width: 695px) {
    .dashboardPage h1{
        width: 100%;
    }
}

.dashNaviLinksDesktop {
    display: none; /* Hide by default */
  }
  
  .dashNaviLinksMobile {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .content {
    padding-top: 250px !important;
    text-align: justify;
}
.content h2 {
    width: 95%;
}
  
  @media (min-width: 515px) and (max-width:670px) {
    .dashNavi h1 {
        margin-left: 100px;
        margin-right: 100px;
    }
    .content {
        padding-top: 250px !important;
        text-align: justify;
    }
    .content h2 {
        width: 95%;
    }
  }
  @media (min-width: 671px) {
    .content {
        padding-top: 155px !important;
    }
  }
  @media (max-width: 670px) {
    #hmLinks {
        transform: translateX(-200vw) translateY(100px) !important;
    }
    #hmLinks.active {
        transform: translateX(0) translateY(100px) !important;
    }
  }
  /* Media query for screens wider than 750px */
  @media (min-width: 960px) {
    .dashNaviLinksDesktop {
        display: flex;
        justify-content: center;
        gap: 30px;
    }
    .currentMeeting {
        width: 60% !important;
    }
    #hmLinksMenu {
        display: none;
    }
  }
.content {
    padding-top: 155px;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}
.voteActive {
    color: greenyellow
}
.dashNavi p {
    font-size: 30px;
    font-weight: 600;
}
.dashNavi a {
    text-decoration: none;
    color: rgb(146, 146, 146);
    transition: .3s color;
}
.dashNavi a:is(:focus, :hover){
    color: green;
}

.dashNavi a#logout:is(:focus, :hover){
    color: red;
}
.currentMeeting {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 90%;
    border: 1px solid white;
    padding: 10px;
}
.currentMeeting p {
    line-height: 20px;
}

#hmLinksMenu {
    z-index: 1001;
    top: 0;
    right: 0;
    width: 50px;
    height: 35px;
    padding: 10px;
    margin-right: 20px;
    transform: translateX(-50%) translateY(20px) scale(0);
    position: absolute;
    transition: transform .5s ease-in-out;
}
#hmLinksMenu span {
    width: 48px;
    height: 3px;
    position: fixed;
    left: 100%;
    transform: translateX(-50%);
    transition: transform .5s ease-in-out, width .5s ease-in-out;
}
#hmLinksMenu span.active:nth-child(1){
    transform: rotate(45deg) translateX(-5px) translateY(25px) !important;
    /* transition: transform .5s ease-in-out; */
}
#hmLinksMenu span.active:nth-child(2){
    transform: scale(0) !important;
    width: 0px;
    /* transition: transform .5s ease-in-out; */
}
#hmLinksMenu span.active:nth-child(3){
    transform: rotate(-45deg) translateX(-24px) translateY(-5px) !important;
    /* transition: transform .5s ease-in-out */
}
#hmLinksMenu span:nth-child(1){
    background-color: gray;
}
#hmLinksMenu span:nth-child(2){
    transform: translateX(-50%) translateY(15px);
    background-color: gray;
}
#hmLinksMenu span:nth-child(3){
    transform: translateX(-24px) translateY(30px);
    background-color: gray;
}
#hmLinks {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 900;
    margin-top: 115px;
    background-color: rgba(0,0,0,0.98);
    transition: transform .75s ease-in-out;
    transform: translate(-200vw);
    padding-top: 5px;

}
#hmLinksBcg {
    display: flex;
    flex-direction: column;
    gap: 35px;
}

#hmLinks a{
    padding-right: 20px;
    font-size: 24px;
}
#hmLinksBcg a:first-child{
    margin-top: 50px;
}

#hmLinksMenu {
    transform: translateX(-50%) translateY(20px) scale(1);
    width: 50px;
    height: 35px;
}
#hmLinks {
    width: 100vw;
}
#hmLinks.active {
    transform: translateX(0);
}