.news {
    border: 1px solid white;
    width: 90%;
    line-height: 20px;
    padding: 20px;
    padding-bottom: 0;
}
@media (min-width: 960px) {
    .news {
        width: 60% !important;
    }
}
.newsFooter {
    display: flex;
    font-size: 14px;
    color: rgb(168, 168, 168);
    padding-top: 15px;
    padding-bottom: 5px;
}
.newsList {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
}
.voteStatus-false {
    color: red;
    font-weight: 800;
    font-size: 28px;
}

.voteStatus-true {
    color: green;
    font-weight: 800;
    font-size: 28px;
}
.votesList {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.vote {
    font-size: 22px;
    display: flex;
    justify-content: space-between;
    padding: 5px 50px 5px 20px;
    align-items: center;
    border: 1px solid white;
    text-decoration: none;
    color: white;
    transition: .3s background-color;
}
.vote-false:is(:focus, :hover) {
    background-color: green;
}
.vote-true {
    background-color: rgba(128, 128, 128, 0.164);
}
.vote-true:is(:focus, :hover) {
    background-color: rgba(255, 0, 0, 0.493);
}

.voteTitle {
    width: 90%;
    text-align: left;
}

.vote-closed {
    background-color: rgba(128, 128, 128, 0.164);
    font-size: 22px;
    display: flex;
    justify-content: space-between;
    padding: 5px 50px 5px 20px;
    align-items: center;
    border: 1px solid white;
    text-decoration: none;
    color: white;
    transition: .3s background-color;
}