.mainPage h1{
    font-size: 72px;
    line-height: 100px;
}
.mainPage p {
    font-size: 20px;
    line-height: 24px;
    width: 80%;
}
.mainPage {
    position: absolute;
    font-family: "Inconsolata", monospace;
    top: 50%;
    left: 50%;
    text-align: center;
    translate: -50% -50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}
form input, form button {
    font-size: 20px;
    font-family: "Inconsolata", monospace;
    padding: 2px;
    border-radius: 2px;
    line-height: 40px;
    transition: .3s background-color;
    color: white;
}
form button {
    border: 1px solid white;
    background-color: transparent;
    color: white;
}
form input::placeholder {
    transition: .3s color;
}
form input:is(:focus)::placeholder {
    color: white;
}
form input:is(:focus), form button:is(:focus, :hover) {
    background-color: green !important;
}
form input:not(:placeholder-shown) {
    background-color: #121212;
    border: 1px solid white;
}
.mainPageRedirects {
    display: flex;
    gap: 20px;
    font-size: 26px;
    margin: 20px;
}
.mainPageRedirects a {
    border: 1px solid greenyellow;
    padding: 5px;
    text-decoration: none;
    color: greenyellow;
    transition: .3s color, .3s border;
}
.mainPageRedirects a:is(:focus, :hover) {
    border: 1px solid green;
    padding: 5px;
    text-decoration: none;
    color: green;
}
