.materialyList {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.material {
    border: 1px solid white;
    padding: 10px;
    text-decoration: none;
    max-width: 100%;
    color: white;
    transition: .3s border, .3s color;
    cursor: pointer;
}
.material:has(:hover, :focus){
    border: 1px solid greenyellow;
    color: greenyellow
}
.materialFooter {
    font-size: 14px;
    text-align: left;
    color: gray;
    padding-top: 5px;
}
.materialTitle {
    margin-left: 10px;
    margin-right: 10px;
}
.materialyModal {
    position: fixed;
    background-color: rgba(0,0,0,0.9);
    width: 100vw;
    height: 100vh;
    z-index: 5;
    top:0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.materialyModalContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    width: 60%;
    height: 60%;
}
#titleBar {
    display: flex;
}
.materialyModal.active {
    display: block;
}
#closeModal {
    color: white;
    cursor: pointer;
    transition: .3s color;
}
#closeModal:focus,#closeModal:hover{
    color: red !important;
}
.materialyModalContent iframe {
    aspect-ratio: 16/9;
}